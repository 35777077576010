<template>
  <v-card
    color="backgroundDarkest"
    rounded="xl"
  >
    <div class="h-full">
      <div class="row-container d-flex flex-md-row flex-column py-5 align-md-center">
        <v-img
          :src="path"
          :height="199"
          :min-width="273"
          :width="273"
        >
        </v-img>
        <div class="ml-md-15">
          <div class="invest-title primary--text">
            {{ $t('components.shared.marketplaceTeaser.desc') }}
          </div>
          <v-btn
            class="mt-3"
            color="white black--text"
            @click="$router.push({ name: 'Marketplace' })"
          >
            {{ $t('components.shared.marketplaceTeaser.button') }}
          </v-btn>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'MarketplaceTeaserCard',
  computed: {
    path() {
      return require('@/assets/img/defaultPortfolio.png');
    },
  },
};
</script>

<style lang="scss" scoped>
.invest-container {
  width: 395px;
}
.invest-title {
  font-weight: 600;
  font-size: 42px;
  line-height: 50px;
}
.row-container {
  width: 728px;
  margin: auto;
}
@media (max-width: 959px) {
  .row-container {
    width: 340px;
  }
}
@media (max-width: 600px) {
  .row-container {
    width: 273px;
  }
  .invest-title {
    font-size: 26px;
    width: 273px;
  }
}
</style>
